@font-face {
  font-family: 'HelveticaNeueLTGEOW82-45Lt';
  src: url('HelveticaNeueLTGEOW82-45Lt.woff2') format('woff2'),
    url('HelveticaNeueLTGEOW82-45Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTGEOW82-75Bd';
  src: url('HelveticaNeueLTGEOW82-75Bd.woff2') format('woff2'),
    url('HelveticaNeueLTGEOW82-75Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTGEOW82-55Rm';
  src: url('HelveticaNeueLTGEOW82-55Rm.woff2') format('woff2'),
    url('HelveticaNeueLTGEOW82-55Rm.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 55 Roman';
  src: url('HelveticaNeueLTGEO-55Roman.woff2') format('woff2'),
    url('HelveticaNeueLTGEO-55Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
