.bannerContainer {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  .banner {
    display: flex;
    width: 100%;

    .bannerImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
