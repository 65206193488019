.container {
  background-color: #f3f4f6;
  flex: 1;
  display: flex;
  flex-direction: column;

  .contentWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
