.container {
  label {
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
  }

  .hasError {
    &:before {
      border: 1px solid var(--secondary-orange) !important;
    }
  }

  .radio {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      padding-left: 34px;
      cursor: pointer;
      color: #242424;
    }

    &:checked + label:before,
    &:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }

    &:checked + label:after {
      content: '';
      width: 15px;
      height: 15px;
      background: #007a3e;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    &:not(:checked) + label:after {
      content: '';
      width: 15px;
      height: 15px;
      background: #007a3e;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    &:not(:checked) + label:hover:after {
      opacity: 1 !important;
    }

    &:not(:checked) + label:hover:before,
    &:checked + label:hover:before {
      border: 1px solid var(--jungle-green);
    }

    &:disabled + label::after {
      background-color: var(--grey-scale-300);
    }

    &:disabled + label {
      color: var(--grey-scale-300);
    }

    &:disabled + label:hover:before {
      border: 1px solid var(--grey-scale-300);
    }
  }
}
