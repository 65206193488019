.container {
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;

  .inputWrapper {
    display: flex;
    flex-direction: column;
  }

  input {
    background: var(--grey-scale-100);
    border: 1px solid var(--grey-scale-200);
    padding: 12px 14px;
    border-radius: 6px;
    font-size: 14px;

    &:focus {
      border: 1px solid var(--jungle-green);
      outline: none;
    }

    &::placeholder {
      font-size: 14px;
      letter-spacing: 0.015em;
      color: var(--grey-scale-400);
    }

    &:disabled {
      color: var(--black);
      &::placeholder {
        color: var(--grey-scale-200);
      }
    }
  }

  .errorIcon,
  .errorText {
    display: none;
  }

  .hasError {
    input {
      border: 1px solid var(--blood-orange) !important;
      &:focus {
        border: 1px solid var(--blood-orange) !important;
      }
    }

    .errorIcon {
      display: block;
      text-align: center;
      position: absolute;
      line-height: 18px;
      top: 14px;
      right: 9px;
      border-radius: 50%;
      border: 1px solid var(--secondary-orange);
      width: 20px;
      height: 20px;
      color: var(--secondary-orange);
      font-size: 12px;
    }

    .errorText {
      display: block;
      color: var(--secondary-orange);
      font-size: 14px;
    }
  }

  fieldset {
    position: relative;
  }

  label {
    font-size: 14px;
    color: var(--secondary-01);
    margin: 0;
    padding-bottom: 5px;

    &.disabled {
      color: var(--grey-scale-300);
    }
  }

  .infoIcon {
    display: block;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    border: 1px solid var(--jungle-green);
    width: 16px;
    height: 16px;
    color: var(--jungle-green);
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }

  .tooltip {
    background: var(--tooltip-background-color);
    color: var(--tooltip-text-color);
    font-size: 14px;
    border-radius: 5px;
    padding: 12px 10px;
    opacity: 1 !important;
  }
}
