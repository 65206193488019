.container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 20, 10, 0.6);
  z-index: 100;
  left: 0;
  top: 0;
}
