.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 122, 62, 0.1);

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    .iconContainer {
      margin-bottom: 24px;
    }

    .title {
      margin-bottom: 10px;
      font-family: 'Helvetica Neue LT GEO 55 Roman';
      font-size: 18px;
      font-weight: bold;
    }

    .description {
      font-size: 16px;
      margin-bottom: 24px;
      color: var(--grey-scale-400);
    }

    .actions {
      display: flex;
      gap: 24px;

      .action {
        cursor: pointer;
      }
    }
  }
}
