.container {
  display: flex;

  .labelConatiner {
    flex: 1;
    margin-top: 3px;

    label {
      font-size: 14px;
    }

    .label {
      display: flex;
      align-items: center;
    }
  }

  .inputContainer {
    position: relative;

    .check {
      &:checked,
      &:not(:checked) {
        position: absolute;
        left: -9999px;
      }

      &:checked + label,
      &:not(:checked) + label {
        position: relative;
        padding-left: 34px;
        cursor: pointer;
        color: #242424;
      }

      &:checked + label:before,
      &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        border: 1px solid #ddd;
        border-radius: 3px;
        background: #fff;
      }

      &:checked + label:after {
        content: '';
        width: 11px;
        height: 22px;
        background: #007a3e;
        background-repeat: no-repeat;
        background-position: 4px 4px;
        background-image: url('../../assets/svgs/check.svg');
        position: absolute;
        color: #fff;
        top: 0px;
        left: 0px;
        padding: 0 6px;
        border-radius: 3px;
        transition: all 0.2s ease;
      }

      &:not(:checked) + label:after {
        content: '';
        width: 14px;
        height: 14px;
        background: #007a3e;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      &:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      &:checked + label:hover:after {
        background-color: var(--light-green);
      }

      &:not(:checked) + label:hover:before {
        border: 1px solid var(--light-green);
      }

      &:not(:checked) + label:hover:before {
        background-repeat: no-repeat;
        background-position: 4px 4px;
      }

      &:disabled + label::after {
        background-color: var(--grey-scale-300);
      }

      &:disabled + label {
        color: var(--grey-scale-300);
      }

      &:disabled + label:hover:after {
        background-color: var(--grey-scale-300);
      }
    }
  }
}
