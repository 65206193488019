.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--cool-grey);

  .infoContainer {
    display: flex;
    flex-direction: row;
    gap: 7px;
    cursor: pointer;
  }

  .languageContainer {
    position: absolute;
    top: 30px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;

    .languageAction {
      cursor: pointer;
      padding: 10px 15px;
      &:hover {
        background-color: var(--pale-grey);
      }
    }
  }
}
