.container {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;

  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 122, 62, 0.1);

  .placeHolder {
    flex: 1;
  }

  .logoContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .languageSelectorContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
