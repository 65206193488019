.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .description {
    flex: 1;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
    color: var(--grey-scale-400);

    .number {
      font-weight: bold;
      color: var(--black);
    }
  }

  .otpContainer {
    flex: 1;
  }

  .separator {
    margin: 0 0.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
  }

  .inputStyle {
    text-align: center;
    width: 3rem !important;
    height: 3rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: var(--grey-scale-100);

    &::placeholder {
      text-align: center;
    }

    &::-webkit-input-placeholder {
      text-align: center;
    }

    &:-moz-placeholder {
      text-align: center;
    }

    &::-moz-placeholder {
      text-align: center;
    }

    &:-ms-input-placeholder {
      text-align: center;
    }
  }

  .resendCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 20px;

    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .active {
      color: var(--jungle-green) !important;
      cursor: pointer;
    }

    .resendAction {
      margin-top: 4px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      color: var(--grey-scale-300);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
