.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 122, 62, 0.1);
  padding: 24px;

  .termsAndConditions {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .datePickerWrapper {
    width: 100%;

    .datePicker {
      display: flex;
      flex: 1;
      background: var(--grey-scale-100);
      border: 1px solid var(--grey-scale-200);
      padding: 12px 14px;
      border-radius: 6px;
      font-size: 14px;
    }
  }

  .pickerContainer {
    display: flex;
    flex-direction: column;
  }

  .radioContainer {
    display: flex;
    flex-direction: column;
  }

  .errorText {
    display: block;
    color: var(--secondary-orange);
    font-size: 14px;
  }
  .labelContainer {
    font-weight: 500;
  }

  label {
    font-size: 14px;
    color: var(--secondary-01);
    margin: 0;
    padding-bottom: 5px;

    &.disabled {
      color: var(--grey-scale-300);
    }
  }

  .termsModal {
    color: var(--jungle-green);
    text-decoration: underline;
    cursor: pointer;
  }

  .radioGroup {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;

    & > * {
      margin-right: 30px;
    }
  }

  .input {
    margin-top: 12px;
  }

  .fullName {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
    font-family: 'Helvetica Neue LT GEO 55 Roman';
  }

  .form {
    margin-bottom: 12px;
  }

  @media only screen and (max-width: 1024px) {
    .fullName {
      flex-direction: column;
    }

    .radioGroup {
      & > * {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
