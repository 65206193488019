.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  transition-property: background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  padding: 15px 20px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  .text {
    display: flex;
    align-items: center;
  }

  .loader {
    animation: spin 2s linear infinite;
    padding: 5px;
  }

  .icon {
    font-size: 24px;
    width: 24px;
    height: 24px;

    & > svg {
      vertical-align: top;
    }
  }

  &.primary {
    background: var(--jungle-green);
    height: 44px;

    .text {
      color: #ffffff;
      font-family: 'Helvetica Neue LT GEO 55 Roman';
    }

    .icon {
      color: #ffffff;
    }

    &:hover {
      cursor: pointer;
      //background: var(--light-green);

      .text,
      .icon {
        color: var(--white);
      }
    }

    &:active {
      outline: none;
      background: var(--jungle-green);

      .text,
      .icon {
        color: var(--white);
      }
    }
  }

  &.secondary {
    height: 44px;
    background: var(--white);
    border: 1px solid var(--jungle-green);
    border-radius: 6px;

    .text,
    .icon {
      font-size: 14px;
      color: var(--jungle-green);
    }

    .text {
      font-family: 'Helvetica Neue Caps';
    }

    &:hover {
      border: 1px solid var(--light-green);
      cursor: pointer;

      .text,
      .icon {
        color: var(--light-green);
      }
    }

    &:active {
      border: 1px solid var(--jungle-green);

      .text,
      .icon {
        color: var(--jungle-green);
      }
    }
  }

  &.additional {
    height: 40px;
    background-color: var(--grey-scale-100);

    .text {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--jungle-green);

      .text,
      .icon {
        color: var(--white);
      }
    }

    &:active {
      background-color: var(--grey-scale-200);

      .text,
      .icon {
        color: var(--black);
      }
    }
  }

  &.round {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.disabled {
    background: #e6e7e8;
    cursor: not-allowed;
    user-select: none;
    border: none;

    &:active {
      border: none;
    }

    .text {
      color: #7f7f7f;
    }

    &:hover {
      background: #e6e7e8;
      border: none;

      .text {
        color: #7f7f7f;
      }
    }
  }
}

.iconLeftAlign {
  svg {
    margin-left: -4px;
  }
}

.iconRightAlign {
  svg {
    margin-right: -4px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
