.container {
  display: flex;
  height: 50px;
  padding: 25px 40px;
  align-items: center;
  border-top: 1px solid #e6e7e8;

  .company {
    flex: 3;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 30px;

    .action {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    height: 70px;
    flex-direction: column;
    padding: 15px 40px;
  }

  .company {
    margin-bottom: 15px;
  }
}
