.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  width: 570px;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 12px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: fadeIn 500ms;
  z-index: 9999;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.05);

  @keyframes fadeIn {
    from {
      // z-index: 9999;
      opacity: 0;
    }

    to {
      // z-index: 8000;
      opacity: 1;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 0px 25px;
    height: 67px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f9 100%);
    border-radius: 12px 12px 0px 0px;
    border-bottom: 1px solid #e6e7e8;

    .title {
      font-weight: bold;
      font-size: 20px;
      color: #000000;
      flex: 1;
    }

    .close,
    .back {
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .content {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    // padding: 25px 25px 0 25px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    padding: 25px;
    border-radius: 0px 0px 12px 12px;
    background: #ffffff;

    &:empty {
      padding: 0;
    }
  }
}

.container.static {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  transform: none;
}

.modalWrapper {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 800;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 30px 0 30px 0;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 90%;
    max-height: 90%;
  }

  .container.stretch {
    width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 0;
    border: 1px solid var(--white);

    .content {
      max-height: 100%;
      height: 100%;
    }
  }

  .content {
    padding: 15px 15px 0 15px !important;
  }

  .footer {
    padding: 15px !important;
  }
}
