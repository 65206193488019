.container {
  display: flex;
  gap: 50px;
  padding: 24px 45px;
  flex: 1;

  .formContainer {
    width: 700px;
    flex: 3;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    padding: 24px 30px;
    gap: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .CheckoutInfo {
    border-radius: 0;
  }
}
