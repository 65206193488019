.sliderWrapper {
  position: relative;
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;

  .keenSlider {
    display: flex;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -khtml-border-radius: 6px;
  }

  .arrow {
    width: 41px;
    height: 41px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;

    &.arrow__left {
      left: 20px;
    }

    &.arrow__right {
      left: auto;
      right: 20px;
    }

    &.arrow__disabled {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;

  .dot {
    border: none;
    width: 8px;
    height: 8px;
    background-color: var(--cool-grey);
    border-radius: 50%;
    margin: 0 5px;
    padding: 6px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.active {
      border: solid 1px var(--jungle-green);
      background-color: #97d700;
      margin-top: -1px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .visible {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
