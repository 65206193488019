.container {
  display: flex;

  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &:not(:last-child) {
      margin: 0 1rem 0 0;
    }
  }
}
