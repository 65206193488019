:root {
  --jungle-green: #007a3e;
  --very-light-blue: #e6e7e8;
  --cool-grey: #99aebb;
  --black: #242424;
  --pale-grey: #f8f8f9;
  --blood-orange: #fe5000;
  --white: #ffffff;
  --dark-forest-green-70: rgba(0, 20, 10, 0.7);
  --light-green: #a5d444;
  --grey-scale-100: #f8f8f9;
  --grey-scale-200: #e6e7e8;
  --grey-scale-300: #c2c2c2;
  --grey-scale-400: #7f7f7f;
  --secondary-01: #99aebb;
  --secondary-orange: #ec5d2a;

  --tooltip-text-color: #242424;
  --tooltip-background-color: #a5d444;
}

.react-datepicker__input-container {
  display: flex !important;
}

#root {
  min-height: 100vh;
  display: flex;
}

html {
  height: 100%;
}

.grecaptcha-badge {
  display: none !important;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: 'HelveticaNeueLTGEOW82-45Lt';
}

strong {
  font-family: 'Helvetica Neue LT GEO 55 Roman';
  font-size: 14px;
  font-weight: bold;
}
